.module-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tab-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.choice-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.5em;
  font-weight: 700;
  color: #234965;
}

.modal-open-btn {
  width: 300px;
  height: 80px;
  font-weight: 700 !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  color: #234965 !important;
  border: 2px solid #234965 !important;
  font-size: 1.5rem !important;
  box-shadow: 0 0 0 0 !important;
  margin-top: 30px;
  font-family: 'Open Sans', sans-serif;
}

.choice-info {
  text-align: center;
  margin-bottom: 10px;
}

.choice-value {
  padding: 7px 20px;
  background-color: transparent;
  border-radius: 5px;
  transition: 1s background-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 8px 0;

  &.flash {
    transition: 0s background-color;
    background-color: rgba(34, 50, 84, 0.55);
  }
}

.choice-info-value {
  font-family: 'Open Sans', sans-serif;
  color: #223254;
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.choice-info-name {
  font-weight: 700;
  font-size: 2.5rem;
  display: inline-block;
  padding: 0 10px 0 10px;
  border-radius: 5px;
}

.choice-info-invalid {
  font-weight: 700;
  font-size: 2rem;
  display: inline-block;
  padding: 3px 10px 3px 10px;
}

.form-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 4px solid #234965;
  border-radius: 5px;
  padding: 10px 15px 10px 15px;
  min-width: 700px;
  min-height: 85px;
}

.form-review-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: .6;
}

.form-review-box {
  align-items: center;
  justify-content: center;
  display: flex;
}

.form-review-title {
  font-family: 'Open Sans', sans-serif;
  color: #234965;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
}

.overview-block {
  margin-top: 3px;
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.overview-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5em;
  font-weight: 600;
  color: #234965;
  display: inline-block;
  min-width: 260px;
  text-align: right;
}

.overview-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8em;
  font-weight: 700;
  color: #234965;
  margin-left: 9px;
  width: 100%;
}

.overview-text-round {
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8em;
  color: #234965;
  margin-left: 9px;
  margin-right: 9px;
  display: inline-block;
  border: 3px solid #234965;
  padding: 0 5px 0 5px;
  border-radius: 5px;
}

.overview-score {
  font-size: 2em;
  color: #234965;
}

.overview-flag-box {
  margin-left: 10px;
  display: inline-block;
  width: 100%;
}

.overview-flag {
  width: 57px;
  height: 39px;
}

.overview-date-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overview-date-title-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 525px;
}

.overview-date-title {
  width: 190px;
  text-align: center;
  font-weight: 700;
  font-size: 1.3rem;
  color: #41484a;
}

.overview-date-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 525px;
  margin-top: 0;
  margin-bottom: 5px;
}

.overview-date {
  width: 190px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.9rem;
  color: #234965;
  font-weight: 700;
}
.overview-date-arrow {
  font-size: 3rem;
  margin-top: -3px;
  font-weight: 100;
  color: #234965;
}

.overview-price-container {
  @extend .form-review;
  padding: 10px 15px 10px 15px;
  margin-top: auto;
  margin-bottom: auto;
  min-width: 600px;
}

.overview-price-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 550px;
}

.overview-price {
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #234965;
}

.overview-subprice-title {
  @extend .overview-price;
  font-size: 1.5em;
  padding: 1px 15px;
}

.overview-subprice {
  @extend .overview-price;
  font-size: 1.5em;
  padding: 1px 15px;
}

.overview-total-box {
  @extend .overview-price-box;
  border-top: 3px solid #234965;
  margin-top: 7px;

  &.borderless {
    border: 0 !important;
  }
}

.overview-total-price {
  @extend .overview-price;
  font-size: 2em;
  padding: 2px 15px;
  font-weight: 700;
}

.overview-price-title {
  @extend .overview-price;
  font-size: 2em;
  padding: 2px 15px;
  font-weight: 700;
}

.server-error-alert {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;
  max-width: 675px;
  display: flex;
  align-items: center;
  border: 4px solid #a70000;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  color: #a70000;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6em;
  font-weight: 700;
  flex-direction: row;
  justify-content: center;

  > * {
    margin: 0 auto;
  }
}

.departure-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  margin-bottom: 50px;
}

.duration-count-btn {
  width: 73px;
  height: 68px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem !important;
  font-weight: 700;
  border-radius: 5px;
  background-color: transparent !important;
  border-color: rgba(35, 73, 101, 0.9) !important;
  color: rgba(35, 73, 101, 0.9) !important;
  box-shadow: 0 0 0 0 !important;
  border-width: 4px !important;
}

.duration-count-borderless {
  @extend .duration-count-btn;
  border-width: 0px !important;
}

.duration-btn-minus {
  margin-right: 45px;
}

.duration-btn-plus {
  margin-left: 45px;
}

.duration-count-container {
  width: 90px;
  display: flex;
  align-items: center;
  height: 110px;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  border: 2px solid rgba(52, 58, 64, 0.56) !important;
}

.duration-count {
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  font-size: 3em;
  color: #41484a;
}

.duration-days {
  width: 100%;
  text-align: center;
  border-top: 2px solid rgba(52, 58, 64, 0.56) !important;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
  color: #41484a;
}

.duration-summary-container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
}

.duration-dates-title-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
}

.duration-dates-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 600px;
  margin-top: 10px;
}

.duration-dates-title {
  width: 190px;
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;
  color: #41484a;
}

.duration-date {
  width: 190px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  color: #234965;
  font-weight: 700;
}

.duration-arrow-right {
  font-size: 4rem;
  margin-top: -8px;
  font-weight: 100;
  color: #234965;
}

.ejerslev-reminder {
  margin-top: 10px;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  color: #234965;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 30px !important;
}

.code-explain {
  min-width: 35%;
  text-align: center;
  font-size: 1.2rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #234965;
  background-color: #2349651a;
  padding: 10px 5px;
  border-radius: 5px;
  margin-top: -10px;
}

.no-spots-warning {
  display: flex;
  align-items: center;
  border: 3px solid #a70000;
  border-radius: 5px;
  padding: 7px 15px 7px 15px;
  color: #a70000;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3em;
  font-weight: 700;
  margin-bottom: 10px;
  flex-direction: row;
  justify-content: center;

  > * {
    margin: 0 15px;
  }
}

.boat-too-big {
  display: flex;
  align-items: center;
  border: 4px solid #ED4337;
  border-radius: 5px;
  padding: 10px 20px 10px 20px;
  color: #ED4337;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5em;
  font-weight: 700;
  flex-direction: row;
  justify-content: center;
  min-width: 1000px;
  margin: 0 auto 10px;

  > * {
    margin: 0 15px;
  }
}

.email-or-phone {
  text-align: center;
  font-size: 2rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  color: #234965;
  padding: 10px 15px;
  border-radius: 5px;
}

.contact-explain {
  min-width: 35%;
  text-align: center;
  font-size: 1.3rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #234965;
  padding: 10px 5px;
  border-radius: 5px;
  margin-top: 10px;
}

.question-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.question-selected {
  background-color: #234965 !important;
  color: white !important;
}

.question-checkbox-btn {
  min-width: 110px !important;
}

.input-modal-btn {
  width: 230px;
  height: 80px;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background-color: #234965 !important;
  color: white !important;
  border-color: #234965 !important;
  font-size: 1.8rem !important;
  border-width: 2px !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
  margin-top: 30px;
}

.component-modal {
  position: absolute;
  top: 80px;
  left: 80px;
  right: 80px;
  bottom: 80px;
  border: 3px solid #234965;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.utility-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.utility-service {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.utility-message-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.1em;
  margin-bottom: 5px;
  font-weight: 700;
  color: #234965;
}

.utilities-container {
  display: flex;
  flex-direction: row;
  width: 1260px;
  flex-wrap: wrap;
  justify-content: center;
}

.utility {
  width: calc(100% * (1/7) - 20px) !important;
  margin: 35px 10px 10px 10px !important;
  height: 195px;
  border-radius: 4px !important;
  background-color: transparent !important;
  color: #234965 !important;
  border: 3px solid #234965 !important;
  box-shadow: 0 0 0 0 !important;
}

.utility-selected {
  background-color: #234965 !important;
  color: white !important;
}

.utility-image {
  width: 65px;
  height: 65px;
}

.utility-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.4rem !important;
  margin-top: 10px;
}

.utility-price{
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  margin-top: 10px;
}


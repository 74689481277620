.test-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
}

.test-row {
  width: 80%;
  text-align: center;
  padding-top: 25px;
  font-size: 1.3rem;
}

.test-btn {
  min-width: 235px;
  font-weight: 600;
  padding: 20px 20px;
  border-radius: 4px;
  background-color: #234965;
  color: white !important;
  border-color: #234965 !important;
  font-size: 1.2rem !important;
  border-width: 0 !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
  margin-top: 45px;
  margin-bottom: 15px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.test-col {
  text-align: center;
}

.test-exit {
  color: #f8f9fa !important;
  background-color: #dc3545d9 !important;
  border-color: #dc354500 !important;
  margin-top: auto;
  margin-left: 20px;
  width: 220px;
  min-width: 220px;
  height: 80px;
  font-weight: 600 !important;
  border-radius: 4px !important;
  font-size: 1.8rem !important;
  border-width: 2px !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 25px;
}

.test-address {
  color: #234965 !important;
  font-size: 2.5rem;
  font-weight: 700;
}

.version {
  color: #234965 !important;
  font-size: 1em;
  font-weight: 400;
}

.contact-text-phone {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  margin-top: 55px;
  font-size: 2rem;
  padding: 0 10%;
  font-weight: 700;
}

.information-message-text {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  margin-top: 150px;
  font-size: 2rem;
  padding: 0 10%;
  font-weight: 500;
}

.contact-text {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  font-size: 2rem;
  padding: 0 10%;
  font-weight: 700;
}

.information-btn {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 3px solid #BBBDBF;
  background-color: #fff;
  color: #BBBDBF;
  font-size: 20px;
}

.information-container {
  display: flex;
  flex-direction: column;
  height: 100%
}

.information-module-btn {
  width: 220px;
  min-width: 220px;
  height: 80px;
  font-weight: 600 !important;
  border-radius: 4px !important;
  font-size: 1.8rem !important;
  border-width: 2px !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 25px;
  color: #f8f9fa !important;
  background-color: #dc3545d9 !important;
  border-color: #dc354500 !important;
  margin-top: auto;
  margin-left: 20px;
}

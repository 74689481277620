.hidden {
  display: none;
}

.inp {
  position: relative;
  width: 100%;
  max-width: 140px;
  margin-left: 20px;
}

.inp input {
  -webkit-appearance: none;
  width: 100%;
  border: 0;
  font-family: 'Open Sans', sans-serif;
  height: 70px;
  font-size: 2.75rem;
  border-bottom: 3px solid #234965;
  background: none;
  border-radius: 0;
  color: #223254;
  transition: all 0.15s ease;
  font-weight: 700;
}

.inp input::-ms-clear{
  display: none;
}

.inp input:hover {
  background: rgba(34,50,84,0.03);
}
.inp input:not(:placeholder-shown) + span {
  color: #5a667f;
  transform: translateY(-26px) scale(0.75);
}

.inp input:focus {
  background: none;
  outline: none;
}

.inpSmall input {
  font-size: 2rem !important;
  height: 57px !important;
  border-bottom: 2px solid #234965 !important;
  font-weight: 600 !important;
}

:root {
  --topBarHeight: 100px;
  --dashboardHeight: calc(100vh - var(--topBarHeight));
}

.codeSnippet {
  background-color: #ebdae3;
  font-size: 2.9em;
  color: #333333;
  border-radius: 4px;
  padding: 2px 5px;
  margin-top: 10px;
}

.codeSnippetBold {
  font-weight: 700;
}

.spinner {
  width: 5rem !important;
  height: 5rem !important;
}

.spinnerContainer {
  width: 100vw;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-container {
  height: var(--dashboardHeight);
}

.vertical-centered {
  flex: 6;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.screensaver-centered {
  flex: 1;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-top: 30px;
}

.screensaver-weather {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-left: 30px;
}

.screensaver-weather-2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
}

.weather-tem {
  font-weight: 600;
  font-size: 3rem;
  color: #234965;
  font-family: 'Open Sans', sans-serif;
}

.weather-wind {
  font-weight: 600;
  font-size: 2.5rem;
  color: #234965;
  font-family: 'Open Sans', sans-serif;
}

.weather-icon {
  font-size: 75px;
  color: #234965;
  margin: 0 20px;
}

.wind-icon {
  width: 95px;
  height: 95px;
  margin: 0 30px;
}

.wind-arrow {
  width: 130px;
  height: 130px;
}

.wind-compass {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.wind-compass-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #234965;
  font-family: 'Open Sans', sans-serif;
}

.wind-compass-arrow {
  border: 3px solid #234965;
  border-radius: 50%;
}

.mid-compass-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.compass-letter {
  margin: 2px 6px;
  font-weight: 700;
  color: #234965;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3rem;
  width: 32px;
  height: 32px;
  text-align: center;
}

.screensaver-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  flex: 1;
  padding-bottom: 30px;
  padding-top: 30px;
}

.topbar-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 300px;
}

.topbar-error {
  background-color: #dc3545d6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  border-radius: 5px;
  color: white;
  font-size: 1.3rem;
  font-weight: 700;
  width: 360px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  margin-left: 36px;
}

.language-btn {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0);
  padding: 6px 8px !important;
  background-color: #e8e9ea !important;
  font-family: 'Open Sans', sans-serif;
}

.language-btn-small {
  //box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0);
  padding: 3px 3px !important;
  background-color: #e8e9ea !important;
  font-family: 'Open Sans', sans-serif;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.language-flag {
  width: 230px;
  height: 173px;
}

.language-flag-small {
  width: 70px;
  height: 53px;
}

.topbar-container {
  height: var(--topBarHeight);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  border-bottom: 1px solid #BBBDBF;
}

.topbar-logo {
  height: 50px;
  object-fit: contain;
}

.dashboard {
  height: var(--dashboardHeight);
}

.selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
  height: var(--dashboardHeight);
}

.selection-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
}

.selection-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
}

.information-btn button {
  width: 230px;
  height: 80px;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background-color: #234965 !important;
  color: white !important;
  border-color: #234965 !important;
  font-size: 1.5rem !important;
  border-width: 2px !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
  margin-right: 20px;
}

.selection-btn {
  min-height: 80px;
  width: 550px;
  color: #5F6267;
  font-size: 2rem !important;
  border-width: 2px !important;
  border-color: #BBBDBF !important;
  font-family: 'Open Sans', sans-serif;
  display: flex !important;
  align-items: center;
  .selection-text-container {
    text-align: left;
    margin-left: 20px;
  }
  .selection-img-container {
    text-align: center;
    width: 80px;
    img {
      width: 60px;
      object-fit: contain;
    }
  }
}

.harbor-name {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 2.8em;
  color: #343a40
}

.harbor-name-screensaver {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-size: 3.5em;
  color: #343a40
}

.btn-sceensaver {
  width: 300px !important;
  height: 105px !important;
}

.nav-container {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.nav-element-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.nav-element-center {
  flex: 2.5;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.nav-element-right {
  flex: 1;
  display: flex;
  justify-content: center;
}

.nav-btn {
  width: 230px;
  height: 80px;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background-color: #234965 !important;
  color: white !important;
  border-color: #234965 !important;
  font-size: 1.5rem !important;
  border-width: 2px !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
}

.nav-next-icon-big{
  font-size: 2.5em;
  margin-left: 10px;
  margin-bottom: -5px;
}

.reset-btn {
  margin-top: auto;
  width: 125px;
  height: 55px;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  opacity: .95;
}

.list-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.option-list-container {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  padding-top: 5px;
  max-width: 1080px;
}

.option-block {
  width: calc(100% * (1/5) - 20px);
  margin: 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
  background-color: transparent !important;
  color: #234965 !important;
  box-shadow: 0 0 0 0 !important;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.option-title {
  font-weight: 600 !important;
  font-size: 1.15rem;
}

.option-price {
  font-weight: 700 !important;
  font-size: 1.6rem;
}

/* Form Group Styles */

.form-item {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  justify-content: center;
}

.form-checkbox-selected {
  background-color: #234965 !important;
  color: white !important;
}

/* Form Group Styles END */

.summary-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0 solid #234965;
  margin-top: 30px;
  border-radius: 5px;
  padding: 20px 70px 25px 70px;
}

.summary-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.9em;
  font-weight: 600;
  color: #234965;
  display: inline-block;
}

.summary-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.1em;
  font-weight: 700;
  color: #234965;
  margin-left: 9px;
}

.summary-check {
  font-size: 3.2rem;
  color: #14b314;
  margin-left: 20px;
}

.summary-optional {
  color: #234965;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.9em;
  font-weight: 700;
  margin-left: 9px;
}

.summary-invalid {
  color: #a70000;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.9em;
  font-weight: 700;
  margin-left: 9px;
}

.duration-arrival {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.duration-container {
  flex: 1;
  width: 550px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
  align-items: center;
}

.duration-arrival-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.5em;
  font-weight: 700;
  color: #234965;
}

.duration-btn-container {
  flex: 1;
  width: 550px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 35px;
}

.duration-btn {
  width: 230px;
  height: 80px;
  font-weight: 700 !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  color: rgba(52, 58, 64, 0.81) !important;
  border-color: rgba(52, 58, 64, 0.56) !important;
  font-size: 1.65rem !important;
  border-width: 2px !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
}

.duration-active-btn {
  border-width: 4px !important;
  border-color: #234965 !important;
  background: #234965 !important;
  color: white!important;
}

.line-break {
  border-bottom: 1px solid #234965;
  width: 700px;
}

.book-one-day {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.5em;
  font-weight: 700;
  color: #234965;
  height: 150px;
  align-items: center;
  display: flex;
  flex: 1;
}

.no-margin {
  margin: 0 !important;
}

.signature-explain {
  min-width: 35%;
  text-align: center;
  font-size: 1.4rem;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  color: #721c24;
  background-color: #f8d7da;
  padding: 15px 20px;
  border-radius: 5px;
}

.welcome-text {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  padding: 0 10%;
  font-weight: 600;
  margin: 110px 0 auto 0;
}

.touch-here-image {
  position: absolute;
  left: 38%;
  bottom: 0;
}

@import "modules/module";
@import "components";
@import "form";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

body {
  font-family: 'Open Sans', sans-serif !important;
}

* {
  -webkit-touch-callout: none !important; /* iOS Safari */
  -webkit-user-select: none !important; /* Safari */
  -khtml-user-select: none !important; /* Konqueror HTML */
  -moz-user-select: none !important; /* Old versions of Firefox */
  -ms-user-select: none !important; /* Internet Explorer/Edge */
  user-select: none !important; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.text-harba-blue {
  color: #234965;
}

.shop-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.shop-nav {
  height: 170px;
  width: 100%;
  margin-top: auto;
}

.serviceContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.title {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.1em;
  font-weight: 700;
  color: #234965;
  margin-bottom: 20px;
}

.service {
  margin: 15px 10px 15px 10px !important;
}

.shop-utility {
  margin: 15px 10px 15px 10px !important;
  width: calc(100% * (1 / 3.5) - 20px) !important;
  height: 225px;
  padding: 18px 10px 0 10px !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  color: #234965 !important;
  border: 3px solid #234965 !important;
  box-shadow: 0 0 0 0 !important;
}

.utility-block {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.amount-block {
  font-weight: 700;
  font-size: 2rem;
}

.image {
  width: 80px;
  height: 80px;
}

.decrement {
  margin-right: 20px;
}

.increment {
  margin-left: 20px;
}

.amount-title {
  display: flex;
  justify-content: center;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 700;
  font-size: 1.5rem;
  color: #234965;
}

.centered-block {
  margin: 15px 55px 0 55px;
}

.duration-container-auto {
  flex: 1;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 25px;
  align-items: center;
}

.services-enabled-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}

.services-enabled {
  display: flex;
  justify-content: center;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 700;
  font-size: 2rem;
  margin: 0 35px;
  color: #234965;
  border-bottom: 3px solid #234965;
}

.price-container-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 4px solid #234965;
  margin-top: 5px;
  padding: 0;
  margin-bottom: auto
}

.price-review-title {
  font-family: "Open Sans", sans-serif;
  font-size: 2.5em;
  font-weight: 700;
  color: #234965
}

.price-container {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-top: 25px;
  align-items: center;
}

.service-price {
  padding: 20px 5px !important;
  height: auto;
  width: 100% !important;
}
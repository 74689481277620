.credit-title {
  font-family: 'Open Sans', sans-serif;
  font-weight: 700 !important;
  color: #234965 !important;
  font-size: 3rem;
  text-align: center;
}

.credit-info-text {
  font-family: 'Open Sans', sans-serif;
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
  max-width: 60%;
  text-align: center;
}

.progressbar-container {
  width: 750px;
  margin-top: 5px;
  margin-left: -20px;
  display: flex;

  ul {
    flex: 1;
  }
}

.progressbar {
  list-style-type: none;

  li {
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 1.2em;
    font-weight: 700;
    color: #234965;
  }
}

.progressbarNoUtil li{
  float: left;
  width: 25% !important;
  position: relative;
  text-align: center;

}

.progressbarSlipway li{
  width: 33.333% !important;
}

.progressbarShop li{
  width: 50% !important;
}

.progressbar li:before{
  content:'';
  width: 40px;
  height: 40px;
  border: 4px solid #bebebe;
  display: block;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  line-height: 27px;
  background: white;
  color: #bebebe;
  text-align: center;
  font-weight: bold;
}

.progressbar li:after{
  content: '';
  position: absolute;
  width:100%;
  height: 5px;
  background: #bebebe;
  top: 18px;
  left: -50%;
  z-index: -1;
}

.progressbar li:first-child:after{
  content: none;
}

.progressbar li.active:after {
  background: #234965;
}
.progressbar li.active:before {
  border-color: #234965;
  background: #234965;
  color: white
}

.p-bar-5 {
  @extend .progressbar;
  li {
    width: 20% !important;
  }
}

.p-bar-4 {
  @extend .progressbar;
  li {
    width: 25% !important;
  }
}

.p-bar-3 {
  @extend .progressbar;
  li {
    width: 33.33% !important;
  }
}

.p-bar-2 {
  @extend .progressbar;
  li {
    width: 50% !important;
  }
}

.p-bar-1 {
  @extend .progressbar;
  li {
    width: 100% !important;
  }
}

.form-nav {
  height: 170px;
  width: 100%;
  display: flex;
  flex-direction: row;

  .nav-block-l {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .nav-block-c {
    position: absolute;
    top: 10px;
  }

  .nav-block-r {
    flex: 1;
    display: flex;
    justify-content: center;
  }
}

.nav-btn {
  width: 180px;
  height: 60px;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background-color: #234965 !important;
  color: white !important;
  border-color: #234965 !important;
  font-size: 1.5rem !important;
  border-width: 2px !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
}

.nav-back-btn {
  @extend .nav-btn;
  font-size: 1.2em;
  margin-right: 10px;
  margin-bottom: -5px;
  align-self: center;
  padding-right: 20px !important;
}

.reset-btn {
  @extend .nav-btn;
  margin-top: auto;
  width: 125px;
  height: 55px;
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  opacity: .95;
}

.nav-next-btn {
  @extend .nav-btn;
  padding-left: 20px !important;
}

.nav-icon {
  font-size: 1.5em;
  margin-bottom: -5px;
}

.nav-next-icon {
  @extend .nav-icon;
  margin-left: 10px;
}

.nav-back-icon {
  @extend .nav-icon;
  margin-right: 10px;
}

.form-field {
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  justify-content: center;
}

.form-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6em;
  font-weight: 700;
  color: #234965;
}

.form-btn {
  min-width: 230px;
  height: 70px;
  margin-left: 15px;
  font-weight: 700 !important;
  border-radius: 4px !important;
  background-color: transparent;
  color: #234965;
  border: 2px solid #234965;
  font-size: 1.5rem !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.print-receipt-button{
  font-size: 3rem !important;
  height: auto;
}
.form-btn-filled {
  color: white !important;
  background-color: #234965 !important;
  opacity: .65;
}

.form-btn-pristine {
  color: white !important;
  background-color: #234965 !important;
}

.form-btn-full {
  color: white !important;
  background-color: #234965 !important;
}

.form-value-invalid {
  color: #ec4135;
  font-family: 'Open Sans', sans-serif;
  font-size: 1em;
  font-weight: 600;
}

.form-value-required {
  color: #ec4135;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3em;
  font-weight: 700;
  margin-top: -18px;
  margin-bottom: 13px;
}

.form-value-optional {
  color: #234965;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.3em;
  font-weight: 700;
  margin-left: 9px;
  margin-top: -18px;
  margin-bottom: 13px;
}

.form-modal {
  position: absolute;
  top: 80px;
  left: 80px;
  right: 80px;
  bottom: 80px;
  border: 3px solid #234965;
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;

  &.country {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
}

.modal-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.country-modal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: calc(100% - 70px);
  width: 100%;
}

.modal-arrow-container {
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
}

.modal-arrow {
  font-size: 5.5rem;
  color: #234965;
}

.modal-option-container {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  padding-top: 5px;
  max-width: 1080px;
}

.modal-search-keyboard {
  max-width: 750px;
  input {
    text-align: center;
  }
}

.modal-option {
  width: calc(100% * (1/4) - 20px);
  margin: 10px;
  font-family: 'Open Sans', sans-serif;
  height: 115px;
  font-weight: 700 !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  color: #234965 !important;
  border: 2px solid #234965 !important;
  font-size: 1.5rem !important;
  box-shadow: 0 0 0 0 !important;

  &.flag-option {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    font-size: 1.75rem !important;
    width: calc(100% * (1/5) - 20px) !important;
    background-color: #d6d7d894;
    height: 165px !important;
  }
}

.modal-flag-icon {
  width: 130px;
  height: 97.6px;
  border: 1px solid #000c;
  border-radius: 2px;
  margin-top: 15px;
}

.active-option {
  background-color: #234965 !important;
  color: white !important;
}

.modal-form-btn {
  min-width: 240px;
  padding: 15px;
  font-weight: 700 !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  color: #234965 !important;
  border: 2px solid #234965 ;
  font-size: 1.4rem !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
}

.modal-form-btn-active {
  min-width: 240px;
  padding: 15px;
  font-weight: 700 !important;
  border-radius: 4px !important;
  background-color: #234965 !important;
  color: #fff !important;
  border: 2px solid #234965 !important;
  font-size: 1.4rem !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
}

.form-label-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.1em;
  font-weight: 700;
  color: #234965;
  margin-bottom: 20px;
}

.form-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

.form-value-check {
  font-size: 3rem;
  color: #14b314;
  margin-left: 3px;
}

.form-value-times {
  font-size: 3rem;
  color: #ED4337;
  margin-left: 3px;
  margin-bottom: -4px;
  width: 1em !important;
}

.form-value-flag {
  width: 62px;
  height: 47px;
  margin: 0 10px 0 10px;
  border-radius: 2px;
  border: 1px solid #000c;
}

.input-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-top: 25px;
}

.modal-message-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.9em;
  font-weight: 700;
  color: #234965;
  margin-bottom: 25px;
  text-align: center;
  width: 100%;
}

.float-input input{
  text-align: center;
}

.float-input div .keyboard {
  max-width: 480px !important;
  left: calc(50% - 240px);
}

.float-input div .keyboard .keyboard-row .keyboard-numberButton {
  display: none !important;
}

.float-input div .keyboard .keyboard-row .keyboard-space {
  display: none !important;
}

.float-input div .keyboard .keyboard-row .shift-symbols{
  display: none !important;
}

.float-input div .keyboard .keyboard-row button:nth-child(11){
  display: none !important;
}

.float-input div .keyboard .keyboard-row button{
  flex-basis: 480px !important;
  font-size: 26px !important;
  height: 90px !important;
}

.input-modal-save {
  width: 230px;
  height: 80px;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background-color: #234965 !important;
  color: white !important;
  border-color: #234965 !important;
  font-size: 1.8rem !important;
  border-width: 2px !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
  margin-top: 30px;
}

.input-container {
  height: 100%;
  width: 100%;
  max-width: 1240px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 0.8;
}

.input-container-bigger {
  flex: 0.95;
}

.input-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
  align-items: flex-end;

  &.form-element {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}

.input-row-vertical {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  align-items: center;
  margin-top: 40px;
}

.form-value-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.9em;
  font-weight: 600;
  color: #234965;
  display: inline-block;
}

.form-flag {
  margin-left: 10px;
  display: inline-block;
}

.form-check {
  font-size: 3.2rem;
  color: #14b314;
  margin-left: 20px;
}

.click-effect {
  transition-duration: 0.4s;
  overflow: hidden;
  position: relative;
}

.click-effect:after {
  content: "";
  background: #fff;
  display: block;
  position: absolute;
  padding: 150%;
  margin-left: -20px !important;
  margin-top: -60%;
  margin-bottom: -60%;
  opacity: .0;
  transition: all .8s
}

.click-effect:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

.click-effect:focus { outline:0; }

.fa-warning {
  color: #eed202;
}

.red-border {
  border-color: #ec4135 !important;
  &:focus {
    outline: none;
  }
}

.red-input-border {
  input {
    border-color: #ec4135 !important;
  }
}

.color-red {
  color: #ec4135;
}

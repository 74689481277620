.payment-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.payment-block {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.message-container {
  color: #234965 !important;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 0 18px 13px 18px;
  margin-top: 5px;
  margin-bottom: 15px;
}

.message-failed-container {
  color: #212529 !important;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0ad4e75;
  border-radius: 5px;
  padding: 13px 18px 13px 18px;
  margin-top: 7%;
  margin-bottom: 25px;
  width: 95%;
  text-align: center;
}

.message-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 3em;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.failed-message-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 2em;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.payment-check {
  font-size: 5.2rem;
  margin-top: 10px;
  color: #14b314;
  margin-left: 20px;
  margin-right: 20px;
}

.review-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 4px solid #234965;
  border-radius: 5px;
  margin-top: -5px;
  padding: 0 20px 15px 20px;
  min-width: 573px;
  min-height: 172px;
}

.credit-review-box {
  border: 4px solid #234965;
  border-radius: 5px;
  padding: 10px;
  min-width: 573px;
  min-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.payment-overview {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.payment-date-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.payment-dates-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 525px;
}

.payment-dates-titles {
  width: 190px;
  text-align: center;
  font-weight: 700;
  font-size: 1.9rem;
  color: #41484a;
}

.payment-date {
  width: 190px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 2.2rem;
  color: #234965;
  font-weight: 700;
}

.payment-amount-text {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  color: #234965;
  font-weight: 700;
  font-size: 2.4rem;
}

.payment-amount-subtext {
  text-align: center;
  font-weight: 600;
  font-size: 1.9rem;
  color: #41484a;
}

.payment-arrow-right {
  font-size: 3.5rem;
  font-weight: 100;
  color: #234965;
}

.payment-data-container {
  margin-top: 5px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.payment-review-label {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.9em;
  font-weight: 600;
  color: #234965;
  display: inline-block;
}

.payment-review-text-round {
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  font-size: 2.1em;
  color: #234965;
  margin-left: 9px;
  margin-right: 9px;
  display: inline-block;
  border: 3px solid #234965;
  padding: 0 5px 3px 5px;
  border-radius: 5px;
}

.payment-review-text {
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  font-size: 2.1em;
  color: #234965;
  margin-left: 9px;
  margin-right: 9px;
  display: inline-block;
  border-radius: 5px;
}

.payment-score {
  font-size: 2.3em;
  color: #234965;
}

.payment-receipt-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 3rem;
  color: #234965;
  font-weight: 600;
  margin-top: 25px;
  flex-direction: column;
}

.payment-receipt-text {
  font-weight: 700;
}

.payment-sticker-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 2.6rem;
  color: #234965;
  font-weight: 700;
  margin-top: 50px;
  flex-direction: row;
}

.payment-sticker-message div {
  border-bottom: 3px solid #234965;
}

.payment-arrow-down {
  font-size: 4.5rem;
  font-weight: 100;
  margin-right: 40px;
  margin-left: 40px;
}

.success-action-container {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 25px;
}

.failed-action-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.payment-btn {
  width: auto;
  min-width: 300px;
  height: 105px;
  font-weight: 600 !important;
  border-radius: 4px !important;
  background-color: #234965 !important;
  color: white !important;
  border-color: #234965 !important;
  font-size: 2.2rem !important;
  border-width: 0 !important;
  box-shadow: 0 0 0 0 !important;
  font-family: 'Open Sans', sans-serif;
  margin-top: 45px;
  margin-bottom: 15px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.payment-exit-btn {
  color: #f8f9fa !important;
  background-color: #dc3545d9 !important;
  border-color: #dc354500 !important;
}

.contactless {
  width: 300px;
  margin-left: 35px;
}

.enter-pin {
  width: 225px;
  margin: 30px 0 40px 35px;
}

.pay-block {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.pay-amount {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.2em;
  font-weight: 700;
  margin-top: 35px;
  color: #234965;
}

.pay-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.5em;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.pay-arrow {
  font-size: 8rem;
  margin-top: 50px;
  font-weight: 100;
  color: #234965;
}

.printer-offline-block {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20%;
}

.printer-offline {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6em;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.printer-offline-continue {
  font-family: 'Open Sans', sans-serif;
  font-size: 2.6em;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 25px;
  margin-top: 40px;
}

.printer-continue-box {
  display: flex;
  justify-content: space-around;
  width: 800px;
}

.payment-abort {
  align-self: flex-start;
  margin-top: auto;
  margin-left: 0 !important;
}

.red-text {
  color: #dc3545d9 !important;
}
